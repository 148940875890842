import { Card, CardWrapper, Container, HeaderWrapper, Logo, PdfViewWrapper, Popop, Wrapper } from './style';
import { IoMdClose } from 'react-icons/io';
import { useRef, useState } from 'react';
import PdfView from './components/view';
import { dataList } from './data';
import { FaFilePdf } from 'react-icons/fa6';

function App() {
  const data = dataList;
  const [isPopup, setIsPopup] = useState(false);
  const [isView, setIsView] = useState(false);
  const [selectedPdfTitle, setSelectedPdfTitle] = useState('');
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [password, setPassword] = useState('');
  const popupRef = useRef();

  const onClickPdfOpen = (e) => {
    const { id } = e.currentTarget;

    const selectedData = data.find((item) => item.id === id);
    if (selectedData) {
      setSelectedPdf(selectedData.file);
      setSelectedPdfTitle(selectedData.title);
    }
    setIsPopup(true);
  };

  const onClickPdfClose = () => {
    setIsView(false);
  };

  const onClickPwCheck = () => {
    const pw = process.env.REACT_APP_PDF_PASSWORD;

    if (pw !== password) {
      return alert('비밀번호가 맞지 않습니다.');
    }

    setIsPopup(false);
    setIsView(true);
    setPassword('');
  };

  const onClickPwClose = () => {
    setIsPopup(false);
    setPassword('');
  };

  const onChangePassword = (e) => {
    const { value } = e.currentTarget;
    setPassword(value);
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <Logo>Yeonho Chung's Academic Papers</Logo>
      </HeaderWrapper>
      <Container>
        <Popop isPopup={isPopup}>
          <div ref={popupRef}>
            <span>password</span>
            <input
              type="password"
              value={password}
              placeholder="비밀번호를 입력해주세요."
              onChange={onChangePassword}
            />
            <div className="pw-btn-wrapper">
              <div className="pw-btn line" onClick={onClickPwClose}>
                취소
              </div>
              <div className="pw-btn" onClick={onClickPwCheck}>
                확인
              </div>
            </div>
          </div>
        </Popop>
        <CardWrapper>
          {data.map((item) => (
            <Card key={`pdf-card-${item.id}`} onClick={onClickPdfOpen} id={item.id}>
              <span>
                <FaFilePdf />
              </span>
              <p>{item.title}</p>
              <div className="hover-text">view</div>
            </Card>
          ))}
        </CardWrapper>
        <PdfViewWrapper isView={isView}>
          <div className="top">
            <p>{selectedPdfTitle}</p>
            <div className="close" onClick={onClickPdfClose}>
              <IoMdClose />
            </div>
          </div>
          <PdfView file={selectedPdf} />
        </PdfViewWrapper>
      </Container>
    </Wrapper>
  );
}

export default App;
