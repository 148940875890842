import styled from '@emotion/styled';

export const PdfWrapper = styled.div`
  position: relative;
  max-width: 100%;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  height: 100vh;
  .react-pdf__Document {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }
`;
export const PageWrapper = styled.div`
  width: 100%;
  canvas,
  .react-pdf__Page__textContent {
    margin: 0 auto;
    max-width: 893px;
    width: 100% !important;
    height: auto !important;
  }
`;
