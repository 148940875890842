import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { PageWrapper, PdfWrapper } from './style';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function PdfView({ file }) {
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <PdfWrapper>
      <Document file={file} style={{ overflow: 'auto' }} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((page, idx) => (
            <PageWrapper key={`page-${idx}`}>
              <Page pageNumber={page} scale={1.5} />
            </PageWrapper>
          ))}
      </Document>
    </PdfWrapper>
  );
}
