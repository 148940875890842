import styled from '@emotion/styled';
import { mediaQuery } from './commons/mediaQuery';

export const Wrapper = styled.div`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
    font-weight: 400;
    font-style: normal;
  }
  background: #fcfcfc;
  padding-top: 80px;
  input {
    border: 1px solid #eee;
    width: 100%;
    height: 40px;
    border-radius: 6px;
    padding: 0 14px;
  }

  input::placeholder {
    color: #aaa;
  }
  input:focus {
    outline: none;
  }

  ${mediaQuery[1]} {
    padding-top: 60px;
  }
`;

export const HeaderWrapper = styled.header`
  background: #fcfcfc;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80px;
  padding-right: 40px;
  border-bottom: 1px solid #eeeeee;
  z-index: 2;
  cursor: default;
  color: #444;

  .gnb {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 16px;
    height: 100%;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 8px 16px 0 16px;
      text-transform: uppercase;
      border-bottom: 3px solid transparent;

      &.active {
        font-weight: bold;
        border-bottom: 3px solid #d91e28;
      }
    }
  }

  ${mediaQuery[0]} {
    padding: 0;
  }
  ${mediaQuery[1]} {
    height: 60px;
  }
`;

export const Logo = styled.div`
  padding: 0 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 900;
  margin-right: auto;
  text-transform: capitalize;
  ${mediaQuery[0]} {
    padding: 0;
    width: 100%;
  }
  ${mediaQuery[1]} {
    font-size: 18px;
  }
`;

export const Container = styled.div`
  padding: 50px;
  ${mediaQuery[0]} {
    padding: 30px 20px;
  }
`;

export const Popop = styled.div`
  transition: background 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: ${({ isPopup }) => (isPopup ? '100vh' : '0')};
  background: ${({ isPopup }) => (isPopup ? 'rgba(0, 0, 0, 0.5)' : 'none')};
  overflow: hidden;

  > div {
    position: absolute;
    top: calc(50% - 100px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 400px;
    height: auto;
    padding: 30px 20px;
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 14px;

    span {
      font-weight: 500;
      text-transform: uppercase;
    }

    .pw-btn-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      .pw-btn {
        transition: background 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 38px;
        background: #383838;
        border-radius: 6px;
        color: #fff;
        cursor: pointer;
        border: 1px solid #383838;

        &:hover {
          background: rgba(56, 56, 56, 0.9);
        }

        &.line {
          color: #383838;
          background: #fff;

          &:hover {
            background: #f5f5f5;
          }
        }
      }
    }
  }

  ${mediaQuery[1]} {
    > div {
      top: 50%;
      width: 90%;
      max-width: 400px;
      height: auto;
      padding: 30px 20px;
      border-radius: 10px;
      background: #fff;
      display: flex;
      flex-direction: column;
      gap: 14px;

      span {
        font-weight: 500;
        text-transform: uppercase;
      }

      .pw-btn-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        .pw-btn {
          transition: background 0.3s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 38px;
          background: #383838;
          border-radius: 6px;
          color: #fff;
          cursor: pointer;
          border: 1px solid #383838;

          &:hover {
            background: rgba(56, 56, 56, 0.9);
          }

          &.line {
            color: #383838;
            background: #fff;

            &:hover {
              background: #f5f5f5;
            }
          }
        }
      }
    }
  }
`;

export const CardWrapper = styled.div`
  > div:first-of-type {
    display: none;
  }
  display: flex;
  align-items: center;
  gap: 20px;
  ${mediaQuery[0]} {
    justify-content: center;
    gap: 20px;
  }
  ${mediaQuery[1]} {
    flex-wrap: wrap;
    gap: 30px;
  }
`;

export const PdfViewWrapper = styled.div`
  transition: opacity 0.6s ease-in-out;
  position: fixed;
  left: 0;
  top: ${({ isView }) => (isView ? 0 : '200vw')};
  opacity: ${({ isView }) => (isView ? 1 : 0)};
  z-index: 99;
  width: 100vw;
  min-height: 100vh;
  background: #fff;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  .top {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #eeeeee;
    justify-content: space-between;
    padding: 0 20px;

    p {
      width: calc(100% - 80px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 500;
    }
    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 50px;
      min-height: 50px;
      cursor: pointer;

      svg {
        color: #4f4f4f;
        width: 28px;
        height: 28px;
      }
    }
  }
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 240px;
  height: 340px;
  background: #d91e28;
  //background: #444;
  padding: 20px;
  color: #fff;
  border-radius: 10px;
  overflow: hidden;

  svg {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
  }

  p {
    width: calc(100% - 40px);
  }

  .hover-text {
    transition: opacity 0.4s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    opacity: 0;
    background: rgba(0, 0, 0, 0.4);
  }

  &:hover {
    .hover-text {
      opacity: 1;
    }
  }

  ${mediaQuery[0]} {
    max-width: 50%;
    width: 100%;
    height: 300px;
  }

  ${mediaQuery[1]} {
    max-width: 80%;
    height: 400px;

    .hover-text {
      display: none;
    }

    &:hover {
      .hover-text {
        opacity: 0;
      }
    }
  }
`;
