export const dataList = [
  {
    id: 'pdf-0',
    title:
      'ex',
    fileName: '2301213818(LSE-PKU)+YEONHO+CHUNG.pdf',
    file: process.env.PUBLIC_URL + '/pdfs/2301213818(LSE-PKU)+YEONHO+CHUNG.pdf',
  },
  {
    id: 'pdf-1',
    title:
      'Post Catch-up Phase of Development: Industrial Policy, Innovation, and Industrial Upgrade - Case of China’s Electric Vehicle Industry',
    fileName: '2301213818(LSE-PKU)+YEONHO+CHUNG.pdf',
    file: process.env.PUBLIC_URL + '/pdfs/2301213818(LSE-PKU)+YEONHO+CHUNG.pdf',
  },
  {
    id: 'pdf-2',
    title:
      'Rise of Chinese Economy in Perspective of Global Value Chain and Supply Chain: Case Study of Electric Vehicles',
    fileName: 'Version+2+Yeonho+Chung+郑然颢+(China+and+Global+Economy).pdf',
    file: process.env.PUBLIC_URL + '/pdfs/Version+2+Yeonho+Chung+郑然颢+(China+and+Global+Economy).pdf',
  },
];
